import React from 'react';

import Navbar from '../navbar';
import Footer from '../footer';
import 'styles/root.scss';
import './styles.scss';

function Layout({
  children,
  hideHeader,
  hideFooter,
  isShowSnackbar = false,
  hideDownload,
}) {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  return (
    <main className={!hideHeader ? 'main' : ''}>
      <header className={hideHeader ? 'd-none' : ''}>
        <Navbar scrollPosition={scrollPosition} isShowSnackbar={isShowSnackbar} />
      </header>
      {children}
      <Footer
        hideFooter={hideFooter}
        hideDownload={hideDownload}
      />
    </main>
  );
}

export default Layout;
