import React from 'react';

import Layout from 'components/layout';
import Seo from 'components/seo';

import Header from 'widgets/@blog/header';
import Feed from 'widgets/@blog/feed';

function Blog() {
  return (
    <Layout>
      <Seo title="Pintarnya Kerja | Blog" />
      <Header />
      <Feed />
    </Layout>
  );
}

export default Blog;
