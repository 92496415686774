import React, { useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Link from 'components/link';
import Button from 'components/button';
import Snackbar from 'components/@home/snackbar';
import SnackbarBottom from 'components/@home/snackbarBottom';
import Logo from 'assets/logo.inline.svg';
import List from 'assets/icons/list.inline.svg';
import Close from 'assets/icons/close.inline.svg';
// import Playstore from 'assets/footer/play-store.svg';
import Instagram from 'assets/footer/instagram.inline.svg';
import Whatsapp from 'assets/footer/whatsapp.inline.svg';
import Facebook from 'assets/footer/facebook.inline.svg';

import './styles.scss';

function Navbar({ scrollPosition, isShowSnackbar = false }) {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.navbar != null);

  const { cta, links, mobilelinks, socials, snackbar } =
    activeEdge.node.frontmatter.navbar;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <>
      {scrollPosition > 1000 && isShowSnackbar ? (
        <Snackbar
          headline={snackbar.top.headline}
          jobseekerText={snackbar.top.jobseeker.text}
          jobseekerLink={snackbar.top.jobseeker.link}
          employerText={snackbar.top.employer.text}
          employerLink={snackbar.top.employer.link}
        />
      ) : null}
      <nav className="grid nav">
        <div className="col-full start-1 nav_col">
          <Link to="/">
            <Logo className="nav_logo" />
          </Link>
          <div className="nav_links">
            {links?.map((link) => (
              <Link
                key={link.text}
                activeClassName="nav_link___active"
                className="text-link"
                to={link.link}
              >
                {link.text}
              </Link>
            ))}
            <Link className="nav_cta" to={cta.link}>
              <Button>{cta.text}</Button>
            </Link>
            <div className="nav_playstore">
              <Link to={snackbar.bottom.button.link}>
                <StaticImage
                  alt="play store"
                  src="../../assets/footer/play-store.svg"
                />
              </Link>
            </div>
            <button className="nav_menu" onClick={toggleMenu}>
              <List />
            </button>
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div className="nav_mobile">
          <button className="nav_mobile_close" onClick={toggleMenu}>
            <Close />
          </button>
          <div className="nav_mobile_links">
            {mobilelinks?.map((link) => (
              <Link
                key={link.text}
                activeClassName="nav_link___active"
                className="text-link"
                to={link.link}
              >
                {link.text}
              </Link>
            ))}
          </div>
          <div className="nav_mobile_footer">
            <Link className="nav_social" to={socials.instagram}>
              <Instagram />
            </Link>
            <Link className="nav_social" to={socials.whatsapp}>
              <Whatsapp />
            </Link>
            <Link className="nav_social" to={socials.facebook}>
              <Facebook />
            </Link>
          </div>
        </div>
      )}
      {scrollPosition > 1000 && isShowSnackbar ? (
        <SnackbarBottom
          headline={snackbar.bottom.headline}
          buttonText={snackbar.bottom.button.text}
          buttonLink={snackbar.bottom.button.link}
        />
      ) : null}
    </>
  );
}

export default Navbar;

const query = graphql`
  query NavbarQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            navbar {
              socials {
                instagram
                whatsapp
                facebook
              }
              links {
                text
                link
              }
              mobilelinks {
                text
                link
              }
              cta {
                link
                text
              }
              snackbar {
                top {
                  headline
                  jobseeker {
                    text
                    link
                  }
                  employer {
                    text
                    link
                  }
                }
                bottom {
                  headline
                  button {
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
