import React from 'react';

import './styles.scss';

function Header() {
  return (
    <section className="grid blogheader">
      <div className="col-full">
        <h1 className="text-title">Blog</h1>
      </div>
    </section>
  );
}

export default Header;
