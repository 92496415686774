import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/link';
import './styles.scss';
import Logo from 'assets/logo.inline.svg';

import useWindowDimensions from 'hooks/useWindowDimensions';

function Footer({ hideFooter, hideDownload }) {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.footer != null);

  const { download, section1, section2, section3, section4, homePartners } =
    activeEdge.node.frontmatter.footer;

  const { width: windowWidth } = useWindowDimensions();
  const currentYear = new Date().getFullYear();
  const isApp = windowWidth < 992;

  return (
    !hideFooter && (
      <footer>
        {!hideDownload && (
          <div className="download grid">
            <div className="download_image start-1 col-6 col-xl-4 col-lg-full">
              <StaticImage
                alt="phone"
                className="download_phone"
                src="../../assets/footer/phone.png"
              />
            </div>
            <div className="download_text col-5 col-xl-3 col-lg-full">
              <h1>{download.headline}</h1>
              <p className="download_subtext">{download.content}</p>
              <div className="download_platforms">
                <Link
                  to={
                    'https://play.google.com/store/apps/details?id=com.pintarnyakerja&referrer=utm_source%3Dwebsite%26utm_medium%3Dwebsite_banner%26utm_term%3Dwebsitebanner%26utm_campaign%3Dwebsitebanner_homepage'
                  }
                >
                  <StaticImage
                    alt="play store"
                    className="download_platforms_image"
                    src="../../assets/footer/play-store.svg"
                    placeholder="blurred"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        <div className="footer grid footer_container">
          {/* Section 1 */}
          <div className="start-1 col-3 col-xl-3 col-lg-full">
            <Logo className="nav_logo" />
            <div className="footernew_complaints">
              <p className="desc_text">{section1.content}</p>
            </div>
          </div>

          {/* Section 2 */}
          <div className="section col-3 col-xl-3 col-lg-full">
            <div className="bold">{section2.subSection1}</div>
            {section2.items1.map((item) => {
              const link = isApp ? item.mobilelinks : item.link;
              return (
                <Link key={item.text} className="desc_text" to={link}>
                  {item.text}
                </Link>
              );
            })}

            <div className="bold mt-2">{section2.subSection2}</div>
            {section2.items2.map((item) => (
              <Link key={item.text} className="desc_text" to={item.link}>
                {item.text}
              </Link>
            ))}
          </div>

          {/* Section 3 */}
          <div className="section col-3 col-xl-3 col-lg-full">
            <div className="bold">{section3.subSection1}</div>
            {section3.items1.map((item) => (
              <Link key={item.text} className="desc_text" to={item.link}>
                {item.text}
              </Link>
            ))}

            <div className="bold mt-2">{section3.subSection2}</div>
            {section3.items2.map((item) => (
              <Link key={item.text} className="desc_text" to={item.link}>
                {item.text}
              </Link>
            ))}
          </div>

          {/* Section 4 */}
          <div className="section col-3 col-xl-3 col-lg-full">
            <div className="bold">{section4.title}</div>
            {section4.items.map((item) => (
              <Link key={item.text} className="desc_text" to={item.link}>
                {item.text}
              </Link>
            ))}
          </div>

          <div className="footer_footer start-1 col-full">
            <p className="footer_footer_p">{`©${currentYear} All Rights Reserved. Pintarnya.`}</p>
          </div>
        </div>
      </footer>
    )
  );
}

export default Footer;

const query = graphql`
  query FooterQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            footer {
              section1 {
                content
                headline
              }
              section2 {
                subSection1
                items1 {
                  link
                  text
                }
                subSection2
                items2 {
                  link
                  text
                }
              }
              section3 {
                subSection1
                items1 {
                  link
                  mobilelinks
                  text
                }
                subSection2
                items2 {
                  link
                  text
                }
              }
              section4 {
                title
                items {
                  text
                  link
                }
              }
              homePartners {
                headline
                companies {
                  logo
                }
                companiesHeadline
              }
              download {
                headline
                content
              }
              email {
                button
                label
                placeholder
              }
            }
          }
        }
      }
    }
  }
`;
