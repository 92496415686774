import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Preview from 'components/@blog/preview';

import './styles.scss';

function Feed() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const articles = edges.filter((edge) => !!edge?.node?.frontmatter?.title);

  const tags = articles.map((article) => article.node.frontmatter.tags).flat();
  const uniqueTags = [...new Set(tags)];

  return (
    <section className="grid feed">
      <div className="start-1 col-8 col-xl-5 col-lg-full">
        {articles.map((article) => {
          const { frontmatter } = article.node;

          return <Preview key={frontmatter.slug} {...frontmatter} />;
        })}
      </div>
      <div className="feed_topics col-4 col-xl-3">
        <p className="feed_topics_title text-gilmer-bold">Topics</p>
        <div className="feed_topics_tags">
          {uniqueTags.map((tag) => (
            <p key={tag} className="feed_tag">
              {tag}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
  query BlogFeedQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            datePublished
            description
            featuredImage
            title
            tags
          }
        }
      }
    }
  }
`;

export default Feed;
