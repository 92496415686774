import React from 'react';

import Link from 'components/link';

import './styles.scss';

function Entry({ datePublished, title, slug, description, tags, featuredImage, showDescription=false }) {
  const date = new Date(datePublished);
  const link = `/blog/${slug}`;

  return (
    <Link to={link}>
      <article className="entry">
        <div>
          <p className="entry_date">{datePublished ? date.toDateString() : ""}</p>
          <h4 className="entry_title">{title}</h4>
          <p className={`entry_desciption${showDescription ? "_show" : ""}`}>{description}</p>
          <div>
            {tags?.map((tag, index) => (
              <>
                <span key={`${tag}-${index}`} className="text-gilmer-medium">
                  {tag}
                </span>
                {index < tags.length - 1 && (
                  <span className="text-gilmer-medium entry_dot"> • </span>
                )}
              </>
            ))}
          </div>
        </div>
        <div>
          {featuredImage ? <img alt="featured" className="entry_image" src={featuredImage} /> : <></> }
        </div>
      </article>
    </Link>
  );
}

export default Entry;
